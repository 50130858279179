import React from 'react';

import VideoActual from './VideoActual';

const Videos = () => {
  return (
    <>
      <section className="container-fluid pt-5 sectionHearth animate__animated animate__fadeIn">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Ponencias</h1>
            </div>
          </div>
        </div>
        <div className="degradadoLineas text-center" />
      </section>

      {/* Paso 5: manda la variable video del use state en vez del video hardcodeado */}
      <VideoActual />
    </>
  )
};

export default Videos;