import React from 'react';

const Input = props => {
  return (
    <div className="form-group">
      <input className="form-control" type="text" required
        {...props}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    </div>
  );
};

export default Input;