import React from 'react';
import { Link } from 'react-router-dom'
import { Logo1, FMD, Logo3, AMD, Logo5 } from '../../img'
import Menu  from './Menu'

const Header = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark py-3">
        <Link to={`/`} className="navbar-brand">
          <img src={Logo1} alt="" width="45" />
        </Link>
        <Link to={`/`} className="navbar-brand">
          <img src={FMD} alt="" width="75" />
        </Link>
        <Link to={`/`} className="navbar-brand">
          <img src={Logo3} alt="" width="75" />
        </Link>
        <Link to={`/`} className="navbar-brand">
          <img src={AMD} alt="" width="75" />
        </Link>
        <Link to={`/`} className="navbar-brand">
          <img src={Logo5} alt="" width="50" />
        </Link>
        {/* <Link to={`/`} className="navbar-brand">
          <img src={LogoLineaCardio} alt="" width="100" />
        </Link> */}
        {/* <form className="form-inline my-2 my-lg-0">
          <input className="form-control mr-sm-2 searchConte" type="text" placeholder="Buscar contenidos" />
        </form> */}
        <Menu />
      </nav>
      <div className="degradadoLineas"></div>
    </header>
  )
};

export default Header;