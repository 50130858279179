import React, { useContext } from 'react';
import { Contexto as SistemaContexto } from '../../contexto/SistemaContexto';

const PonenciaOndemand = ({ setLinkVideo, setModalTitle, setDescVideo, descripcion, nombreVideo, titulo, portada }) => {
  const { cleanVideoSelect } = useContext(SistemaContexto);

  return (
    <div className="col-12 col-md-6 col-lg-4 my-3" data-toggle="modal" data-target="#modalVimeo"
      onClick={() => {
        setLinkVideo(nombreVideo);
        setModalTitle(titulo);
        setDescVideo(descripcion);
      }}
      onMouseEnter={() => cleanVideoSelect()}
    >
      <h5>{titulo}</h5>
      <p className="grayText boxDataVideo">
        {descripcion}
      </p>
      <img src={portada} className="img-fluid" alt="img video" />
    </div>
  );
};

export default PonenciaOndemand;