import database from '../firebase/Firebase';
import crearContextoDatos from './crearContextoDatos';
import { BAJAR_METRICAS } from '../acciones/accionesContexto';

const reducerMetricas = (state = null, action) => {
  switch (action.type) {
    case BAJAR_METRICAS:
      return { ...state, tiempoVistoVideo: action.payload };
    default:
      return state;
  };
};

/**
 * Saber en dónde se quedó el video la última vez
 * @param {string} uid 
 * @param {string} modulo - modulo actual seleccionado
 * @param {string} tituloVideo - video actual mostrado
 */
const getMetricasVideo = dispatch => {
  return async (uid, tituloVideo) => {
    if (uid && tituloVideo) {
      const videoSinPunto = tituloVideo.split('.');
      await database.ref(`usuarios/${uid}/${videoSinPunto[0]}`)
        .once('value')
        .then(snapshot => {
          if (snapshot.val()) {
            const tiempoVistoVideo = snapshot.val().segsActualVideo;
            dispatch({ type: BAJAR_METRICAS, payload: tiempoVistoVideo });
          }
        })
        .catch(e => console.log('Error bajando las métricas', e));
    }
  };
};

const updateTiempoVideo = () => {
  return async (uid, tituloVideo, segsActualVideo, segsActualEnHora) => {
    if (uid && tituloVideo) {
      const videoSinPunto = tituloVideo.split('.');
      await database.ref(`usuarios/${uid}/${videoSinPunto[0]}`).update({ segsActualVideo, segsActualEnHora })
        .catch(e => console.log('Error actualizando métricas video', e));
    }
  };
};

const updateVideoTerminado = () => {
  return async (uid, tituloVideo) => {
    if (uid && tituloVideo) {
      const videoSinPunto = tituloVideo.split('.');
      await database.ref(`usuarios/${uid}/${videoSinPunto[0]}`).update({ terminado: true })
        .catch(e => console.log('Error actualizando video terminado', e));
    }
  };
};

const updateYaReprodujoVideo = () => {
  return async (correo, perfil, nombre, tituloVideo) => {
    if (perfil && tituloVideo) {

      const date = new Date();
      const registroHoy = await window.localStorage.getItem(`registroHoy-dia-${tituloVideo}-${date.getDate()}-mes${date.getMonth()}`);

      if (!registroHoy) {
        const videoSinPunto = tituloVideo.split('.');
        const hoy = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        await database.ref(`videosVistos/${perfil}/${videoSinPunto[0]}/${hoy}/${nombre}`).update({ correo, nombre })
          .catch(e => console.log('Error mostrando que ya vio el video hoy', e));
        await window.localStorage.setItem(`registroHoy-dia-tituloVideo-${date.getDate()}-mes${date.getMonth()}`, 'true');
      }
    }
  }
}

export const { Contexto, Proveedor } = crearContextoDatos(
  reducerMetricas,
  {
    getMetricasVideo, updateTiempoVideo, updateVideoTerminado, updateYaReprodujoVideo
  },
  { tiempoVistoVideo: 0, videoCompletado: false }
);