import React, { useContext, useEffect, useRef, useState } from 'react';
import { Contexto as SistemaContexto } from '../../contexto/SistemaContexto';
import { Contexto as MetricasContexto } from '../../contexto/MetricasContexto';
import { Contexto as UsuariosContexto } from '../../contexto/UsuariosContexto';
import { PortadaVideo } from '../../img';
import PonenciaOndemand from './Ondemand';

const VideoActual = () => {
  const { state: { url, videos }, getAllVideos, getVideoSeleccionado, cleanVideoSelect } = useContext(SistemaContexto);
  const { state: { tiempoVistoVideo }, getMetricasVideo, updateTiempoVideo, updateVideoTerminado, updateYaReprodujoVideo } = useContext(MetricasContexto);
  const { state: { correo, nombre, perfil, uid } } = useContext(UsuariosContexto);
  const [linkVideo, setLinkVideo] = useState();
  const [descVideo, setDescVideo] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [tiempoActual, setTiempoActual] = useState(0);

  const refVideo = useRef(null);

  useEffect(() => {
    getAllVideos(perfil);
    // eslint-disable-next-line
  }, [perfil]);

  useEffect(() => {
    if (linkVideo) {
      getVideoSeleccionado(perfil, linkVideo);
    }
    // eslint-disable-next-line
  }, [perfil, linkVideo]);


  /**
   * TODA LA PARTE DE MÉTRICAS:
   */

  const segsToHora = segundos => {
    let d = new Date(segundos * 1000);
    let minuto =
      d.getMinutes() <= 9 ? "0" + d.getMinutes() : d.getMinutes();
    let segundo =
      d.getSeconds() <= 9 ? "0" + d.getSeconds() : d.getSeconds();
    return /* hora + ":" +  */minuto + ":" + segundo;
  };

  /**
   * Actualizar variables de estado para tener cuánto tiempo
   * @param {number} tiempoTotal - Tiempo total del video en curso
   * @param {number} tiempoReproduccion - Segundo en el que va el video
   */
  const metricaVideo = async (tiempoTotal, tiempoReproduccion) => {
    const segundosRestantes = tiempoTotal - tiempoReproduccion;
    // Diferencia de 10 segundos en el tiempo
    if (tiempoActual + 10 < tiempoReproduccion || tiempoActual - 10 > tiempoReproduccion) {
      setTiempoActual(tiempoReproduccion);
      const segsActualEnHora = segsToHora(tiempoReproduccion);

      // LLAMDA A LA BASE DE DATOS
      await updateTiempoVideo(uid, descVideo, tiempoReproduccion, segsActualEnHora);
    }

    if (segundosRestantes < 11) {
      // Indicar que el video fue visto - BASE DE DATOS
      await updateVideoTerminado(uid, descVideo);
    }
  };

  /** Si la persona ya había empezado este video */
  useEffect(() => {
    if (refVideo.current) {
      refVideo.current.currentTime = tiempoVistoVideo;
    }
  }, [refVideo, tiempoVistoVideo]);

  useEffect(() => {
    if (uid && descVideo) {
      getMetricasVideo(uid, descVideo);
    }
    // eslint-disable-next-line
  }, [uid, descVideo]);

  useEffect(() => {
    if (correo && perfil && nombre && descVideo) {
      updateYaReprodujoVideo(correo, perfil, nombre, descVideo);
    }
    // eslint-disable-next-line
  }, [correo, perfil, nombre, descVideo]);

  return (
    <>
      <section id="VideoActual" className="container-fluid pt-5 px-0 sectionHearth animate__animated animate__fadeIn">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12 col-lg-12 mb-3 mb-lg-0 text-center">
              <div className="row mb-3">
                {
                  videos.map(video => {
                    return <PonenciaOndemand
                      key={video.titulo}
                      setLinkVideo={setLinkVideo}
                      setModalTitle={setModalTitle}
                      setDescVideo={setDescVideo}
                      nombreVideo={video.nombreVideo}
                      titulo={video.titulo}
                      descripcion={video.descripcion}
                      portada={PortadaVideo}
                    />
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Modal --> */}
      <div className="modal fade" id="modalVimeo" tabIndex="-1" role="dialog" aria-labelledby="modalVimeoTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalVimeoTitle">{modalTitle}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                onClick={() => cleanVideoSelect()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="iframecontainer">
                <video
                  id="VideoSeleccionado"
                  src={url}
                  controls
                  controlsList="nodownload"
                  ref={refVideo}
                  onTimeUpdate={() => {
                    const v = document.getElementById("VideoSeleccionado");
                    const segTotales = Math.floor(v.duration);
                    const segundos = Math.ceil(v.currentTime);
                    metricaVideo(segTotales, segundos);
                  }}
                ></video>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"
                onClick={() => cleanVideoSelect()}
              >Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
    </>
  )
};

export default VideoActual;