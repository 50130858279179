import React from 'react';

const Checkbox = ({ id, checked, onChange, lblText, anchor }) => {
  return (
    <div className="form-group">
      <div className="form-check form-check-inline">
        <input id={id} className="form-check-input" type="checkbox" required
          checked={checked} onChange={e => onChange(e.target.checked)}
        />
        <label htmlFor={id} className="form-check-label">
          {anchor ?
            <a href={anchor} target="_blank" rel="noopener noreferrer"><u>{lblText}</u></a>
            :
            <u>{lblText}</u>
          }
        </label>
      </div>
    </div>
  );
};

export default Checkbox;