import React, { useContext } from 'react';
import { TemarioPaciente, TemarioMedicos, Mapamundi } from '../img';
import { Contexto as UsuariosContexto } from '../contexto/UsuariosContexto';

const Agenda = () => {
  const { state: { emailVerified, perfil } } = useContext(UsuariosContexto);

  const renderTemario = () => {
    if (!emailVerified) {
      return (
        <div className="col-12 text-center">
          <p>
            Contenido únicamente para miembros, si no es miembro regístrese.
                <br /><br />
                Si ya tienes cuenta ingrese para ver los contenidos.
              </p>
          <img src={Mapamundi} className="img-fluid mt-4 mb-5" width="600" alt="" />
        </div>
      );
    }

    if (perfil === 'Paciente') {
      return (
        <div className="col-12">
          <img src={TemarioPaciente} className="img-fluid" alt="Agenda" />
        </div>
      );
    }

    return (
      <div className="col-12">
        <img src={TemarioMedicos} className="img-fluid" alt="Agenda" />
      </div>
    );
  };

  return (
    <section className="sectionHearth pt-4 animate__animated animate__fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Temario</h1>
          </div>
          {renderTemario()}
        </div>
      </div>
      <div className="degradadoLineas"></div>
    </section>
  )

};

export default Agenda;