import database, { firebase } from '../firebase/Firebase';
import crearContextoDatos from './crearContextoDatos';
import { REGISTRO, LOGIN, LOGOUT } from '../acciones/accionesContexto';

const reducerUsuarios = (state, action) => {
  switch (action.type) {
    case REGISTRO:
      return { ...state, nombre: action.payload.nombre, correo: action.payload.mail, emailVerified: false };
    case LOGIN:
      return { ...state, nombre: action.payload.nombre, perfil: action.payload.perfil, correo: action.payload.mail, emailVerified: action.payload.emailVerified, uid: action.payload.uid };
    case LOGOUT:
      return { ...state, perfil: null, correo: null, emailVerified: null };
    default:
      return state;
  }
};

const registroUsuario = dispatch => {
  return async (nombre, apellido, mail, password, telefono, pais, ciudad, especialidad, cedula) => {
    await firebase.auth().createUserWithEmailAndPassword(mail, password)
      .then(async response => {
        await database.ref(`usuarios/${response.user.uid}`).update({
          nombre, apellido, mail, telefono, pais, ciudad, especialidad, cedula
        }).catch(e => console.log(e));

        await firebase.auth().currentUser.sendEmailVerification();

        dispatch({ type: REGISTRO, payload: { nombre, mail } });

      }).catch(e => {
        console.clear();
        console.log(e.code);
      });
  };
};

const loginUsuario = dispatch => {
  return async (email, contrasenia) => {
    await firebase.auth().signInWithEmailAndPassword(email, contrasenia)
      .then(async response => {
        const { uid } = response.user; // emailVerified, 
        await window.localStorage.setItem("session", "1");

        await database.ref(`usuarios/${uid}`)
          .once('value')
          .then(snapshot => {
            if (snapshot.val()) {
              const { cedula, nombre } = snapshot.val();
              dispatch({ type: LOGIN, payload: { perfil: cedula, nombre, mail: email, emailVerified: true, uid } });
            } else {
              dispatch({ type: LOGIN, payload: { perfil: 'Paciente', nombre: null, mail: email, emailVerified: true, uid: null } });
            }
          });
      });
  };
};

const resetPassUsuario = () => {
  return async emailAddress => {
    var resetExitoso = false;
    await firebase.auth().sendPasswordResetEmail(emailAddress)
      .then(() => {
        // Email sent.
        resetExitoso = true;
      }).catch(e => {
        console.log('Ocurrió un error al mandar el correo de cambio de contraseña', e);
      });
    return resetExitoso;
  };
};

const logoutUsuario = dispatch => {
  return async () => {
    await firebase.auth().signOut();
    await window.localStorage.clear();
    dispatch({ type: LOGOUT });
  };
};

export const { Contexto, Proveedor } = crearContextoDatos(
  reducerUsuarios,
  {
    registroUsuario, loginUsuario, resetPassUsuario, logoutUsuario
  },
  {
    nombre: null, correo: null, emailVerified: null, perfil: null, uid: null
  }
);