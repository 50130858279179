import React, { useContext, useEffect } from 'react';

import { Contexto as SistemaContexto } from '../../contexto/SistemaContexto';

import Input from '../../modules/Inputs/Input';
import Checkbox from '../../modules/Inputs/Checkbox';

const Registro = ({ nombre, setNombre, apellido, setApellido, mail, setMail, password, setPassword, telefono, setTelefono, pais, setPais, ciudad, setCiudad, perfil, setPerfil, especialidad, setEspecialidad, checkbox, setcheckbox, handleRegistro, perfilListo, setPerfilListo, invitadoPor, setInvitadoPor }) => {
  const { state: { especialidades, perfiles } } = useContext(SistemaContexto);

  useEffect(() => {
    if (perfil === "Paciente") {
      setPerfilListo(true);
    } else {
      if (especialidad) {
        setPerfilListo(true);
      } else {
        setPerfilListo(false);
      }
    }
    // eslint-disable-next-line
  }, [perfil, especialidad]);

  return (
    <>
      <form id="Registro" className="Registro animate__animated animate__fadeInLeft"
        onSubmit={e => e.preventDefault()}>
        <p>Cree una cuenta</p>
        <Input autoFocus id="Nombre" placeholder="Nombre(s)"
          value={nombre} onChange={setNombre}
        />
        <Input id="Apellido" placeholder="Apellido(s)"
          value={apellido} onChange={setApellido}
        />
        <Input id="Correo" type="email" placeholder="Correo"
          value={mail} onChange={setMail}
        />
        <Input id="Telefono" type="tel" placeholder="Teléfono"
          value={telefono} onChange={setTelefono}
        />
        <Input id="Pais" placeholder="País"
          value={pais} onChange={setPais}
        />
        <Input id="Ciudad" placeholder="Ciudad"
          value={ciudad} onChange={setCiudad}
        />
        <div className="form-group">
          <label>Invitado por:</label>
          <select className="form-control" required
            value={invitadoPor} onChange={e => setInvitadoPor(e.target.value)}
          >
            <option value=''>Seleccione quien lo invitó</option>
            <option value='CAIPaDI'>CAIPaDI</option>
            <option value='MSD'>MSD</option>
            <option value='Servier'>Servier</option>
          </select>
        </div>
        
        <div className="form-group">
          <label>Perfil</label>
          <select className="form-control" required
            value={perfil} onChange={e => setPerfil(e.target.value)}
          >
            <option value=''>Seleccione</option>
            {perfiles.map(perfil => {
              return <option key={perfil} value={perfil}>{perfil}</option>;
            })}
          </select>
        </div>
        {
          perfil === "Profesional de la salud" && (
            <div className="form-group">
              <label>Especialidad</label>
              <select className="form-control" required
                value={especialidad} onChange={e => setEspecialidad(e.target.value)}
              >
                <option value=''>Seleccione su especialidad</option>
                {especialidades.map(especialidad => {
                  return <option key={especialidad} value={especialidad}>{especialidad}</option>;
                })}
              </select>
            </div>
          )
        }
        <Input id="password" type="password" placeholder="Contraseña"
          value={password} onChange={setPassword}
        />
        {/* <Input id="CedulaProf" placeholder="Cédula Profesional"
                value={cedula} onChange={setCedula}
              /> */}
        <div className="degradadoLineas mb-4"></div>
        <Checkbox id="TerminosCondiciones" checked={checkbox} onChange={setcheckbox}
          lblText='Acepto los términos y condiciones.'
        />
        <div className="form-group text-center">
          <button id="btnRegistrarme" type="submit" className="btn btnBranding"
            onClick={() => handleRegistro()}
            disabled={!nombre || !apellido || !mail || !password || !telefono || !pais || !ciudad || !perfilListo || !checkbox || !invitadoPor} 
          >
            Registrarme
                </button>
        </div>
      </form>
      <div id="MailSuccess" className=" animate__animated animate__fadeInLeft" style={{ display: "none" }}>
        <div className="degradadoLineas mt-3 mb-4"></div>
        <h3 className="mb-3">Registrado con éxito</h3>
        <p>
          Recibirás un correo electrónico para confirmar tu cuenta.
              </p>
      </div>
    </>
  );
};

export default Registro;