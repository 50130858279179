import React, { useContext } from 'react';
import { Mapamundi } from '../img/index.js';
import Videos from '../modules/Videos/Videos.js';
import { Contexto as UsuariosContexto } from '../contexto/UsuariosContexto';

const Ponencias = () => {
  const { state: { emailVerified } } = useContext(UsuariosContexto);

  if (!emailVerified) {
    return (
      <section className="sectionHearth pt-4 animate__animated animate__fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 text-center">
            <img src={Mapamundi} className="img-fluid my-5" alt="CDV" />
          </div>
          <div className="col-12 col-lg-6 text-center">
            <p>
              Contenido únicamente para miembros, si no es miembro regístrese.
              <br /><br />
              Si ya tienes cuenta ingrese para ver los contenidos.
            </p>
          </div>
        </div>
      </div>
      <div className="degradadoLineas"></div>
    </section>
    );
  }

  return (
    <Videos />
  );
};

export default Ponencias;