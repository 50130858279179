import React from 'react';
import { PonentesImg } from '../img';

const Ponentes = () => {
  return (
    <section className="sectionHearth pt-4 animate__animated animate__fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Ponentes</h1>
            <img src={PonentesImg} className="img-fluid" alt="Ponentes" />
          </div>
        </div>
      </div>
      <div className="degradadoLineas"></div>
    </section>
  );
};

export default Ponentes;