import React, { useContext, useState } from 'react';
import { Mapamundi } from '../../img';
import { Contexto as UsuariosContexto } from '../../contexto/UsuariosContexto';
import { ToggleElems } from '../../utils/DisplayElems';

// import TermsCond from '../../files/Terminos-y-condiciones-CVD-Academy.pdf';
import MailChimpForm from '../../modules/mailchimp';
import Login from './Login';
import Registro from './Registro';

const Inicio = () => {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [telefono, setTelefono] = useState('');
  const [pais, setPais] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [invitadoPor, setInvitadoPor] = useState('');
  const [perfil, setPerfil] = useState('');
  const [especialidad, setEspecialidad] = useState('');
  const [perfilListo, setPerfilListo] = useState(false);
  const [checkbox, setcheckbox] = useState(false);

  const { registroUsuario } = useContext(UsuariosContexto);

  const handleRegistro = async () => {
    if (nombre && apellido && mail && password && telefono && pais && ciudad && perfilListo && invitadoPor) {
      await registroUsuario(nombre, apellido, mail.toLocaleLowerCase(), password, telefono, pais, ciudad, especialidad, perfil);

      // Envío automático a mailchimp
      document.getElementById('SubmitMailchimp').childNodes[0].click();

      ToggleElems('Registro', 'MailSuccess');
      setTimeout(() => {
        ToggleElems('MailSuccess', 'Login');
      }, 4000);

      setNombre('');
      setApellido('');
      setMail('');
      setPassword('');
      setTelefono('');
      setPerfil('');
      setEspecialidad('');
      setcheckbox(false);
    }
  };

  return (
    <section className="container-fluid pt-4">
      <div className="row">
        <div className="col-12 col-lg-6 text-center d-flex align-items-center">
          <img className="img-fluid animate__animated animate__fadeInLeft" src={Mapamundi} alt="" />
          <MailChimpForm
            nombre={nombre}
            apellido={apellido}
            mail={mail}
            telefono={telefono}
            especialidad={especialidad}
            ciudad={ciudad}
          />
        </div>
        <div className="col-12 col-lg-6">
          <div className="contenedorLoginRegistro animate__animated animate__fadeInLeft py-4 px-5">
            <h3>
              ¡Genial, está a unos pasos de ser
              parte del 5o Evento Conmemorativo!
            </h3>
            <p>Para continuar inicie sesión o regístrese</p>
            <div className="pb-3 text-center">
              <button id="btnLogin" type="button" className="btn btnIniciarSesion mr-3"
                onClick={() => ToggleElems('Registro', 'Login')}>
                Iniciar Sesión
              </button>
              <button id="btnRegistro" type="button" className="btn btnBranding"
                onClick={() => ToggleElems('Login', 'Registro')}>
                Registrarme
              </button>
            </div>

            <Registro
              nombre={nombre}
              setNombre={setNombre}
              apellido={apellido}
              setApellido={setApellido}
              mail={mail}
              setMail={setMail}
              password={password}
              setPassword={setPassword}
              telefono={telefono}
              setTelefono={setTelefono}
              pais={pais}
              setPais={setPais}
              ciudad={ciudad}
              setCiudad={setCiudad}
              perfil={perfil}
              setPerfil={setPerfil}
              especialidad={especialidad}
              setEspecialidad={setEspecialidad}
              checkbox={checkbox}
              setcheckbox={setcheckbox}
              handleRegistro={handleRegistro}
              perfilListo={perfilListo}
              setPerfilListo={setPerfilListo}
              invitadoPor={invitadoPor}
              setInvitadoPor={setInvitadoPor}
            />

            <Login
              mail={mail}
              setMail={setMail}
              password={password}
              setPassword={setPassword}
              ToggleElems={ToggleElems}
            />
          </div>
        </div>
        <div className="col-12 my-3">
          <p className="creditos text-center m-0">
            <i>Evento para pacientes y profesionales de la salud.</i>
          </p>
        </div>
      </div>
      <div className="degradadoLineas"></div>
    </section>
  )
};

export default Inicio;