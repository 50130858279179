import database, { storageRef } from '../firebase/Firebase';
import crearContextoDatos from './crearContextoDatos';
import { OBTENER_ESPECIALIDADES, OBTENER_PERFILES, OBTENER_VIDEOS, BAJAR_VIDEO } from '../acciones/accionesContexto';

const reducerSistema = (state = null, action) => {
  switch (action.type) {
    case OBTENER_ESPECIALIDADES:
      return { ...state, especialidades: action.payload };
    case OBTENER_PERFILES:
      return { ...state, perfiles: action.payload };
    case OBTENER_VIDEOS:
      return { ...state, videos: action.payload };
    case BAJAR_VIDEO:
      return { ...state, url: action.payload };
    default:
      return state;
  };
};

const getAllEspecialidades = dispatch => {
  return async () => {
    await database.ref('especialidades')
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          const especialidades = Object.keys(snapshot.val());
          dispatch({ type: OBTENER_ESPECIALIDADES, payload: especialidades });
        }
      })
      .catch(e => {
        console.log('Error obteniendo las especialidades', e);
      });
  };
};

const getAllPerfiles = dispatch => {
  return async () => {
    await database.ref('perfiles')
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          const perfiles = Object.keys(snapshot.val());
          dispatch({ type: OBTENER_PERFILES, payload: perfiles });
        }
      })
      .catch(e => {
        console.log('Error obteniendo los perfiles', e);
      });
  };
};

const getAllVideos = dispatch => {
  return async (perfil) => {
    const directorio = perfil === 'Paciente' ? 'Pacientes' : 'Profesionales';
    await database.ref(`videos/${directorio}`)
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          const videos = snapshot.val();
          dispatch({ type: OBTENER_VIDEOS, payload: videos });
        }
      })
      .catch(e => {
        console.log('Error obteniendo los videos.', e);
      });
  };
};

const getVideoSeleccionado = dispatch => {
  return (perfil, nombreArchivo) => {
    const directorio = perfil === 'Paciente' ? 'Pacientes' : 'Profesionales';
    const refArchivo = storageRef.ref(`Videos/${directorio}/${nombreArchivo}`);
    refArchivo.getDownloadURL()
      .then(url => {
        dispatch({ type: BAJAR_VIDEO, payload: url });
      }).catch(e => {
        console.log('Error bajando doc', e);
      });
  };
};

const cleanVideoSelect = dispatch => {
  return () => {
    dispatch({ type: BAJAR_VIDEO, payload: null });
  };
};

export const { Contexto, Proveedor } = crearContextoDatos(
  reducerSistema,
  {
    getAllEspecialidades, getAllPerfiles,
    getAllVideos, getVideoSeleccionado, cleanVideoSelect
  },
  { especialidades: [], perfiles: [], videos: [], url: null }
);