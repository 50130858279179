import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App.js';
import { Proveedor as UsuariosProveedor } from './components/contexto/UsuariosContexto';
import { Proveedor as MetricasProveedor } from './components/contexto/MetricasContexto';
import { Proveedor as SistemaProveedor } from './components/contexto/SistemaContexto';

ReactDOM.render(
  <React.StrictMode>
    <SistemaProveedor>
      <UsuariosProveedor>
        <MetricasProveedor>
          <App />
        </MetricasProveedor>
      </UsuariosProveedor>
    </SistemaProveedor>
  </React.StrictMode>,
  document.getElementById('root')
);