import React from 'react';
/* import { AcercaDeImg } from '../img'; */

const AcercaDe = () => {
  return (
    <section className="sectionHearth pt-4 animate__animated animate__fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Acerca de</h1>
            {/* <img src={AcercaDeImg} className="img-fluid" alt="Ponentes" /> */}
            {/* <img src={CVD} className="img-fluid mb-4" alt="CDV" />
            <p className="text-justify normalText">
              Cardiovascular Diseases Academy (CVD Academy), le da la más cordial bienvenida a contenidos actualizados en enfermedades crónicas, abordando temas como la Hipertensión, la angina de pecho y la insuficiencia cardiaca, de viva voz de expertos nacionales y de Latinoamérica en las diferentes áreas. <br /><br />
              
              CVD Academy consta de los siguientes 4 módulos que se lanzarán de manera mensual y permanecerán “on demand” en esta plataforma: <br /><br />
              
              <b>Módulo 1-</b> Hipertensión arterial: la otra pandemia (3 de noviembre) <br />
              <b>Módulo 2-</b> Optimización del tratamiento de la HTA (1 de diciembre) <br />
              <b>Módulo 3-</b> Angina microvascular, actualización 2021 (4 de enero) <br />
              <b>Módulo 4-</b> Mejorando los resultados de los pacientes con insuficiencia cardiaca (2 de febrero) <br /><br />
              
              El curso cuenta con valor curricular por parte del Consejo Mexicano de Cardiología. Se emitirá una constancia al haber terminado las evaluaciones, válida por puntos de recertificación. <br/><br/>

              Objetivos que alcanzarán los participantes: <br/><br/>
              -	Se actualizarán en la epidemiología y fisiopatología de la HTA, angina de pecho e IC <br/>
              -	Conocerán la importancia de la fenotipificación de estas enfermedades crónicas <br/>
              -	Aprenderán sobre el diagnóstico e importancia de la intervención temprana en los pacientes con estas enfermedades crónicas <br/>
              -	Reconocerán la relevancia de evitar complicaciones cardiovasculares y cómo lograrlo <br/>
              <br />
              <h3 className="text-center">¡Bienvenid@!</h3>
              <br />
            </p> */}
          </div>
        </div>
      </div>
      <div className="degradadoLineas"></div>
    </section>
  );
};

export default AcercaDe;