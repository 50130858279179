import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzjpb5Mi9BFXZkfapfqYeVBTvZoVkZUOw",
  authDomain: "caipadi-elearning.firebaseapp.com",
  databaseURL: "https://caipadi-elearning.firebaseio.com",
  projectId: "caipadi-elearning",
  storageBucket: "caipadi-elearning.appspot.com",
  messagingSenderId: "147265609867",
  appId: "1:147265609867:web:c6b38f6fb80a6601dbb48e",
  measurementId: "G-NEZ84NER6C"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const database = firebase.database();;
const storageRef = firebase.storage();

export { firebase, database as default, storageRef };