import React, { useContext } from 'react';

import { Contexto as UsuariosContexto } from '../../contexto/UsuariosContexto';

import Input from '../../modules/Inputs/Input';

const Login = ({ mail, setMail, password, setPassword, ToggleElems }) => {
  const { resetPassUsuario, loginUsuario } = useContext(UsuariosContexto);

  return (
    <>
      <form  id="Login"  className="Login animate__animated animate__fadeInLeft" style={{ display: "none" }}>
        <p>Inicie sesión con su cuenta</p>
        <Input id="CorreoLog" type="email" placeholder="Correo"
          value={mail} onChange={setMail}
        />
        <Input id="passwordLogin" type="password" placeholder="Contraseña"
          value={password} onChange={setPassword}
        />
        <div className="form-group">
          <small className="pointer"
            onClick={() => ToggleElems('Login', 'RecoveryPassword')}
          >
            <u>Olvidé mi contraseña</u>
          </small>
        </div>
        <div className="form-group text-center">
          <button type="button" className="btn btnBranding"
            onClick={() => loginUsuario(mail, password)}
            disabled={!mail || !password}
          >
            Entrar
                </button>
        </div>
      </form>
      <div className="Login animate__animated animate__fadeInLeft" style={{ display: "none" }}>
        <div className="degradadoLineas mt-3 mb-4"></div>
        <h3 className="mb-3">Estaremos en línea pronto</h3>
        <p>Le esperamos a partir del día 13 de noviembre para comenzar.</p>
      </div>
      <form id="RecoveryPassword" className="RecoveryPassword animate__animated animate__fadeInLeft" style={{ display: "none" }}>
        <p>Ingrese su Email para reestablecer su contraseña</p>
        <Input id="CorreoRecovery" type="email" placeholder="Correo Electrónico"
          value={mail} onChange={setMail}
        />
        <div className="form-group text-center">
          <button type="button" className="btn btnBranding"
            onClick={() => {
              resetPassUsuario(mail.toLocaleLowerCase());
              setMail('');
              ToggleElems('RecoveryPassword', 'Mailrestablecer')
              setTimeout(() => {
                ToggleElems('Mailrestablecer', 'Login');
              }, 4000)
            }}
            disabled={!mail}
          >
            Reestablecer
                </button>
        </div>
      </form>

      <div id="Mailrestablecer" className=" animate__animated animate__fadeInLeft" style={{ display: "none" }}>
        <div className="degradadoLineas mt-3 mb-4"></div>
        <h3 className="mb-3">Envío exitoso</h3>
        <p>
          Se envió un correo electrónico para reestablecer su contraseña, por favor revise su email.
            </p>
      </div>
    </>
  );
}

export default Login;