import React, { useContext, useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { ACERCADE, AGENDA, PONENTES, CONTACTO, PONENCIAS } from './acciones/RutasNames';
import './App.css';

import Header from './modules/General/Header';
import Footer from './modules/General/Footer';

import Inicio from './screens/LoginPath/Inicio.js';
import AcercaDe from './screens/Acerca-de.js';
import Agenda from './screens/Agenda.js';
import Ponentes from './screens/Ponentes.js';
import Ponencias from './screens/Ponencias.js';
import Contacto from './screens/Contacto.js';

import { Contexto as SistemaContexto } from './contexto/SistemaContexto';
import { Contexto as UsuariosContexto } from './contexto/UsuariosContexto';

const App = () => {
  const { getAllEspecialidades, getAllPerfiles } = useContext(SistemaContexto);
  const { state: { emailVerified } } = useContext(UsuariosContexto);

  useEffect(() => {
    getAllEspecialidades();
    getAllPerfiles();
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path='/' component={Inicio}>
          {!!emailVerified && <Redirect to={`/${PONENCIAS}`} />}
        </Route>
        <Route exact path={`/${ACERCADE}`} component={AcercaDe} />
        <Route exact path={`/${AGENDA}`} component={Agenda} />
        <Route exact path={`/${PONENTES}`} component={Ponentes} />
        <Route exact path={`/${PONENCIAS}`} component={Ponencias} />
        <Route exact path={`/${CONTACTO}`} component={Contacto} />
      </Switch>
      <Footer />
    </Router>
  )
};

export default App;