import MailchimpSubscribe from "react-mailchimp-subscribe";

const url = "https://sistemasintegrales.us17.list-manage.com/subscribe/post?u=cb4fa0d48af3085303659db71&amp;id=1535e3b730";

const MiForm = ({ onValidated, nombre, apellido, mail, telefono, especialidad, ciudad, perfil }) => {
  const submit = () => {
    const specialty = especialidad ? especialidad : perfil;

    onValidated({
      EMAIL: mail,
      FNAME: nombre,
      LNAME: apellido,
      PHONE: telefono,
      SPECIALTY: specialty,
      CITY: ciudad
    });
  };

  return (
    <div style={{ display: 'none' }} id="SubmitMailchimp">
      <button onClick={submit}>
        MC
      </button>
    </div>
  );
};

const MailChimpForm = ({ nombre, apellido, mail, telefono, especialidad, ciudad, perfil }) => {
  return (
    <div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe }) => (
          <MiForm
            onValidated={formData => subscribe(formData)}
            nombre={nombre}
            apellido={apellido}
            mail={mail}
            telefono={telefono}
            especialidad={especialidad}
            ciudad={ciudad}
            perfil={perfil}
          />
        )}
      />
    </div>
  )
};

export default MailChimpForm;