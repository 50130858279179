export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTRO = 'registro';

export const OBTENER_ESPECIALIDADES = 'OBTENER_ESPECIALIDADES';
export const OBTENER_PERFILES = 'OBTENER_PERFILES';
export const OBTENER_VIDEOS = 'OBTENER_VIDEOS';
export const BAJAR_VIDEO = 'BAJAR_VIDEO';
export const SET_PONENCIAS = 'SET_PONENCIAS';

export const BAJAR_METRICAS = 'BAJAR_METRICAS';