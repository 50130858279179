import React from 'react';
import { Mapamundi } from '../img';

const Contacto = () => {
  return (
    <section className="sectionHearth pt-4 animate__animated animate__fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1>Contacto</h1>
            <img src={Mapamundi} className="img-fluid my-5" width="350" alt="CDV" />
            <p className="normalText my-3">
              Para nosotros es muy importante escuchar sus inquietudes; por favor de ser necesario no dude en escribirnos para resolver sus dudas o atender a sus comentarios.
            </p>
          </div>
        </div>
        <div className="row my-4 d-flex justify-content-center">
          <div className="col-12 col-lg-6 text-center">
            <h3><i className="fas fa-headset"></i></h3>
            <h4>Para soporte técnico:</h4>
            <p className="normalText">
              Para cualquier problema relacionado con la plataforma por favor, escribanos directamente a la dirección<br /><a href="mailto:soportecvd@sistemasintegrales.mx">soportecaipadi-dmd@sistemasintegrales.mx</a>. <br /><br />
              O llámenos al siguiente número: 55 5454 9285.
            </p>
          </div>
          {/* <div className="col-12 col-lg-6 text-center">
            <h3><i className="fas fa-notes-medical"></i></h3>
            <h4>Para información médica:</h4>
            <p className="normalText">
              Para atención en información médica, por favo envíe sus preguntas y comentarios al correo <a href="mailto:informacion.medica.mx@servier.com">informacion.medica.mx@servier.com</a>.
            </p>
            <p className="normalText">
              Para reportar los eventos adversos, por favor escríbanos al correo <a href="mailto:farmacovigilancia.mx@servier.com">farmacovigilancia.mx@servier.com</a>.
            </p>
          </div> */}
        </div>
      </div>
      <div className="degradadoLineas"></div>
    </section>
  );
};

export default Contacto;