import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MSD } from '../../img';
import { Contexto as UsuariosContexto } from '../../contexto/UsuariosContexto';

const Footer = ({ children }) => {
  const anio = new Date().getFullYear();
  const { state: { emailVerified, perfil } } = useContext(UsuariosContexto);

  return (
    <>
      <section className="container-fluid px-0 section2">
        <div className="container">
          <div className="row">
            {
              !!emailVerified && perfil !== 'Paciente' ?
                <div className="col-12 text-center">
                  <img className="img-fluid my-4" src={MSD} alt="MSD" width="300" />
                </div>
                :
                <div className="col-12 text-center py-5">
                </div>
            }
          </div>
        </div>
        <div className="degradadoLineas2 text-center"></div>
      </section>
      <footer className="container-fluid pt-5">
        <div className="row">
          <div className="col-12 text-center">
            <p className="creditos">
              Copyright &copy; Caipadi {anio}. <Link to='/' rel="noopener noreferrer">Aviso de Privacidad.</Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  )
};

export default Footer;