import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AGENDA, CONTACTO, PONENCIAS } from '../../acciones/RutasNames';
import { Contexto as UsuariosContexto } from '../../contexto/UsuariosContexto';

const Menu = () => {
  const { state: { emailVerified }, logoutUsuario } = useContext(UsuariosContexto);

  return (
    <>
      <button className="navbar-toggler hidden-lg-up" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation"></button>
      <div className="collapse navbar-collapse" id="collapsibleNavId">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          {/* <li className="nav-item">
            <NavLink to={`/${ACERCADE}`} className="nav-link" activeClassName="active">
              Acerca de
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink to={`/`} className="nav-link" activeClassName="active">
              Inicio
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/${AGENDA}`} className="nav-link" activeClassName="active">
              Agenda
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink to={`/${PONENTES}`} className="nav-link" activeClassName="active">
              Ponentes
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink to={`/${PONENCIAS}`} className="nav-link" activeClassName="active">
              Ponencias
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/${CONTACTO}`} className="nav-link" activeClassName="active">
              Contacto
            </NavLink>
          </li>
          <li className="nav-item">
            {!!emailVerified ?
              <div className="nav-link"
                onClick={() => logoutUsuario()}
              >
                <i className="fa fa-sign-out" aria-hidden="true"></i>
              </div>
              :
              <Link to={`/`} className="nav-link">
                <i className="fa fa-sign-in" aria-hidden="true"></i>
              </Link>
            }
          </li>
        </ul>
      </div>
    </>
  );
};

export default Menu;